@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

html {
  font-family: "Poppins", sans-serif;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  overflow: visible !important;
}

* {
  margin: 0;
  padding: 0;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-radio {
  @apply bg-[#ebeced] dark:bg-[#ebeced] h-[40px] transition-opacity duration-300 focus:ring-0 focus:ring-offset-0 dark:disabled:bg-slate-700/30 dark:disabled:border-slate-700 dark:disabled:hover:border-slate-700;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded-[10px];
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply text-[15px] text-gray-900 dark:text-gray-900 leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600 dark:focus:border-slate-600;
}

.form-input,
.form-textarea {
  @apply placeholder-slate-400 dark:placeholder-slate-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-primary checked:bg-primary border-slate-300 focus:border-indigo-300;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 13px;
}

.scrollbar::-webkit-scrollbar-track {
  width: 3px;
  border-radius: 5px;
  background: #f0f0f0;
  padding: 2px;
}

.scrollbar::-webkit-scrollbar-thumb {
  width: 3px;
  background: #d3d3d3;
  border-radius: 5px;
  margin: 2px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
}

::-webkit-scrollbar {
  width: auto;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #9fa0a1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.form-checkbox {
  -webkit-user-select: none; /* Safari/Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Standard */
  -webkit-appearance: none; /* Safari */
}

input[type="checkbox"] {
  -webkit-appearance: checkbox; /* Safari */
}
